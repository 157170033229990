<script setup lang="ts">
import type { Promotion } from "@/types";

const { t } = useT();
const { open } = useNlcModals();
const loginGuard = useLoginGuard();
const { data: appInitData } = useAppInitData();
const { depositInfoData, timerWheelDisabled } = useDepositStreakData();

defineProps<{
	title: string;
	subTitle?: string;
	type?: Promotion["type"];
	image: string;
}>();

const buttonName = computed(() => {
	if (appInitData.value?.isGuest) {
		return t("Register & play");
	}
	if (depositInfoData?.value?.secondsToResetStreakCounter) {
		return timerWheelDisabled.value;
	}
	if (depositInfoData?.value?.canWheel) {
		return t("Spin & Win");
	}
	return t("Make purchase");
});

const handleClick = () => {
	if (depositInfoData?.value?.secondsToResetStreakCounter) {
		return;
	}
	dispatchGAEvent({
		event: "click_button",
		button_name: "make_purchase",
		location: "promotions"
	});
	if (depositInfoData?.value?.canWheel) {
		open("LazyOModalWheelOfWinsPlayWheel");
		return;
	}
	window?.$cash?.$router?.push?.("/cash/deposit-by-money/");
};

const handleClickInfo = () => {
	loginGuard({
		success: () => {
			open("LazyOModalWheelOfWinsHowItWorks");
		},
		fail: () => open("LazyOModalSignup")
	});
};

const finishedAt = computed(() => {
	if (depositInfoData?.value?.finishedAt && depositInfoData?.value?.finishedAt !== "0000-00-00 00:00:00") {
		return depositInfoData?.value?.finishedAt;
	}
	return appInitData?.value?.depositStreak?.finishedAt;
});
</script>

<template>
	<MPromotionWrapper
		:type="type"
		:title="title"
		:image="image"
		buttonInfo
		:buttonName="buttonName"
		:buttonDisabled="!!depositInfoData.secondsToResetStreakCounter"
		@click-info="handleClickInfo"
		@click-card="handleClick"
	>
		<template #top>
			<div class="box-image">
				<div class="wrapper">
					<MWheelOfWinsFakeWheel />
				</div>
				<NuxtImg
					src="/nuxt-img/promotions/deposit-streak-coins.png"
					alt="coins"
					format="avif"
					loading="lazy"
					width="664"
					height="528"
					class="coins"
				/>
			</div>
		</template>

		<AText :size="16" :modifiers="['linght']">
			{{ subTitle }}
		</AText>

		<MWheelOfWinsStepsProgressBar showNumber />

		<MCounterBadgeGroup v-if="finishedAt" :timestamp="finishedAt ?? ''" :badgeText="t('Limited Offer')" />
	</MPromotionWrapper>
</template>

<style scoped lang="scss">
.box-image {
	display: flex;
	width: 100%;
	position: relative;
	margin: -6px 0 -20px;

	.wrapper {
		display: flex;
		overflow: hidden;
		height: 160px;
		width: 100%;
		position: relative;
	}

	.coins {
		width: 332px;
		height: 264px;
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		z-index: 2;
	}

	&:after {
		content: "";
		display: flex;
		width: 100%;
		height: 38px;
		position: absolute;
		bottom: -1px;
		left: 0;
		background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #620289 100%);
		z-index: 1;
	}
}

.fake-wheel {
	position: absolute;
	width: 290px;
	height: 290px;
	padding: 18px;
	top: 26px;
	left: 50%;
	transform: translateX(-50%);
	z-index: 1;
}

.progress {
	margin: 4px 0 6px;
	padding: 8px;

	&:deep(.step) {
		span {
			font-weight: 500;
			color: var(--additional-a-2);
		}

		&.active {
			background: var(--additional-a-2) !important;

			span {
				color: var(--neutral-15);
			}
		}
	}
}
</style>
